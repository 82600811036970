import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function Hero() {
  const { t, i18n } = useTranslation();
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: (i18n.language === "ar" ? 14 : 10), sm: (i18n.language === "ar" ? 20 : 16) },
          pb: { xs: 6, sm: 10 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            {t('welcome')}&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              {t('company')}
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            {t('hero_description')}
          </Typography>

        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 4, sm: 6 },
            alignSelf: 'center',
            height: 'max-content',
            width: '100%',
            maxWidth: { xs: '100%', sm: '60%' },
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        >
          <Stack
            direction={{ xs: 'column', sm: 'column' }}
            alignSelf="center"
            spacing={2}
            useFlexGap
            sx={{ p: 2, width: { xs: '100%', sm: 'auto' }, m: 'auto' }}
          >
            <Typography
              textAlign="center"
              color="text.secondary"
              sx={{ alignSelf: 'center', fontSize:'14pt', width: { sm: '100%', md: '80%', fontWeight:'bold' } }}
            >
              ** {t('leavemessage')} **
            </Typography>

            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your name"
              placeholder={t('entername')}
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your name',
              }}
            />
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your phone number"
              placeholder={t('enterphone')}
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your phone number',
              }}
            />
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder={t('enteremail')}
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your email address',
              }}
            />
            <Button variant="contained" color="primary">
            {t('sendnow')}
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
