import React from 'react';
import '../../css/gbackdrop.css';
export default function GeneralBackdrop() {
    return (
        <div className='general-backdrop'>
            <div className='backdrop-circle'>
                <div className='circle-border'></div>
                <span className='backdrop-letter'>D</span>
            </div>
        </div>
    )
}
