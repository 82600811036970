import React, { useEffect } from 'react';
import './App.css'
import { useTranslation } from 'react-i18next';
import './i18n';
import LandingPage from './landing-page/LandingPage'
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';


export default function App() {
  const { t, i18n } = useTranslation();



  // *********************************************************************


  // *************** Don't forget to make blogs website ******************



  // *********************************************************************



  useEffect(() => {
    const updateDir = (lang) => {
      const direction = lang === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = direction; // Set direction on the root HTML element
      document.body.dir = direction; // Optionally set on body as well, for consistency
    };

    // Initial direction update
    updateDir(i18n.language);

    // Listen for language changes
    i18n.on('languageChanged', updateDir);

    // Cleanup function
    return () => {
      i18n.off('languageChanged', updateDir);
    };
  }, [i18n]);

  return (
    <BrowserRouter>
      <div>
        {/* <Helmet>
          <html lang={i18n.language} />
          <title>{t('app_page_title')}</title>
          <meta name="app_page_description" content={t('description')} />
        </Helmet> */}
        <LandingPage />
      </div>
    </BrowserRouter>
  );
}