import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Box, Backdrop, CircularProgress, Button } from '@mui/material';
import GeneralBackdrop from './gbackdrop'
import { FormControl } from '@mui/material';

const lightMapStyles = [
    {
        "elementType": "geometry",
        "stylers": [{ "color": "#f5f5f5" }]
    },
    {
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#616161" }]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [{ "color": "#f5f5f5" }]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#bdbdbd" }]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{ "color": "#eeeeee" }]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#757575" }]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{ "color": "#e5e5e5" }]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#9e9e9e" }]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{ "color": "#ffffff" }]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#757575" }]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{ "color": "#dadada" }]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#616161" }]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#9e9e9e" }]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{ "color": "#e5e5e5" }]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [{ "color": "#eeeeee" }]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{ "color": "#c9c9c9" }]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#9e9e9e" }]
    }
];

const darkMapStyles = [
    {
        "elementType": "geometry",
        "stylers": [{ "color": "#212121" }]
    },
    {
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#757575" }]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [{ "color": "#212121" }]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [{ "color": "#757575" }]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#757575" }]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{ "color": "#2c2c2c" }]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#8a8a8a" }]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{ "color": "#373737" }]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{ "color": "#3c3c3c" }]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [{ "color": "#4e4e4e" }]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#616161" }]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#757575" }]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{ "color": "#000000" }]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#3d3d3d" }]
    }
];


const travelModes = [
    { mode: 'DRIVING', icon: <DriveEtaIcon />, label: 'Driving' },
    { mode: 'WALKING', icon: <DirectionsWalkIcon />, label: 'Walking' },
    { mode: 'TRANSIT', icon: <DirectionsTransitIcon />, label: 'Transit' },
    { mode: 'BICYCLING', icon: <DirectionsBikeIcon />, label: 'Bicycling' },
  ];
  
  const GoogleMap = () => {
    const theme = useTheme();
    const mapRef = useRef(null);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const [showTravelModes, setShowTravelModes] = useState(false);
    const [travelMode, setTravelMode] = useState('DRIVING');
    const [travelTime, setTravelTime] = useState('');
    const [availableModes, setAvailableModes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [directionsActive, setDirectionsActive] = useState(false);
    const [navigationActive, setNavigationActive] = useState(false);
  
    useEffect(() => {
      const initMap = () => {
        mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 25.2507295, lng: 55.3406983 },
          zoom: 12,
          mapTypeId: 'roadmap',
          mapTypeControl: false,
          styles: theme.palette.mode === 'dark' ? darkMapStyles : lightMapStyles,
          fullscreenControl: false,
          zoomControl: false,
          scrollwheel: true,
          gestureHandling: 'greedy',
          streetViewControl: true,
          disableDefaultUI: true
        });
  
        directionsServiceRef.current = new window.google.maps.DirectionsService();
        directionsRendererRef.current = new window.google.maps.DirectionsRenderer();
        directionsRendererRef.current.setMap(mapRef.current);
  
        new window.google.maps.Marker({
          position: { lat: 25.2507295, lng: 55.3406983 },
          map: mapRef.current,
          title: "Devozilla Information Technology"
        });
      };
  
      const loadScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBYG15-tMf-dceCoAidEBCPzviXQsavpUU&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = initMap;
        document.head.appendChild(script);
      };
  
      if (!window.google) {
        loadScript();
      } else {
        initMap();
      }
  
      return () => {
        if (document.head.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBYG15-tMf-dceCoAidEBCPzviXQsavpUU&libraries=places"]`)) {
          document.head.removeChild(document.head.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBYG15-tMf-dceCoAidEBCPzviXQsavpUU&libraries=places"]`));
        }
      };
    }, [theme.palette.mode]);
  
    const checkAvailableModes = (start) => {
      const validModes = [];
      let checkedModes = 0;
  
      travelModes.forEach(({ mode }) => {
        directionsServiceRef.current.route(
          {
            origin: start,
            destination: { lat: 25.2507295, lng: 55.3406983 },
            travelMode: window.google.maps.TravelMode[mode],
          },
          (response, status) => {
            checkedModes += 1;
            if (status === 'OK') {
              validModes.push(mode);
            }
            if (checkedModes === travelModes.length) {
              setAvailableModes(validModes);
              setLoading(false);
              if (validModes.length > 0) {
                setTravelMode(validModes[0]);
                calculateAndDisplayRoute(validModes[0], start);
              }
            }
          }
        );
      });
    };
  
    const calculateAndDisplayRoute = (mode, start) => {
      directionsServiceRef.current.route(
        {
          origin: start,
          destination: { lat: 25.2507295, lng: 55.3406983 },
          travelMode: window.google.maps.TravelMode[mode],
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRendererRef.current.setDirections(response);
            const duration = response.routes[0].legs[0].duration.text;
            setTravelTime(duration);
          } else {
            setTravelTime('');
          }
        }
      );
    };
  
    const handleDirectionsButtonClick = () => {
      if (directionsActive) {
        setDirectionsActive(false);
        setShowTravelModes(false);
        setAvailableModes([]);
        setTravelTime('');
        directionsRendererRef.current.setDirections({ routes: [] });
        setNavigationActive(false);
      } else {
        setLoading(true);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const start = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            setShowTravelModes(true);
            setDirectionsActive(true);
            checkAvailableModes(start);
          }, () => {
            setLoading(false);
            window.alert('Error: The Geolocation service failed.');
          });
        } else {
          setLoading(false);
          window.alert('Error: Your browser doesn\'t support geolocation.');
        }
      }
    };
  
    const handleTravelModeClick = (mode) => {
      setTravelMode(mode);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const start = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          calculateAndDisplayRoute(mode, start);
        });
      }
    };
  
    const handleStartNavigation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const start = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setNavigationActive(true);
          calculateAndDisplayRoute(travelMode, start);
        });
      }
    };
  
    return (
      <div style={{ position: 'relative', height: '300px', width: '100%', borderRadius:'10px', overflow:'hidden' }}>
        <div id="map" style={{ height: '100%', width: '100%' }}></div>
        <IconButton
          onClick={handleDirectionsButtonClick}
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
          color="primary"
        >
          {directionsActive ? <CloseIcon /> : <DirectionsIcon />}
        </IconButton>
        {showTravelModes && (
          <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 1, display: 'flex', gap: '8px' }}>
            {travelModes.map((item) => (
              <IconButton
                key={item.label}
                onClick={() => handleTravelModeClick(item.mode)}
                color={travelMode === item.mode ? 'secondary' : 'primary'}
                disabled={!availableModes.includes(item.mode)}
              >
                {item.icon}
              </IconButton>
            ))}
          </Box>
        )}
        {travelTime && (
          <div
            style={{
              width: 'max-content',
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 1,
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              padding: '0px 0px 0px 10px',
              borderRadius: '5px',
              boxShadow: theme.shadows[3],
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Estimated Time: {travelTime}
            <IconButton
              color="primary"
              sx={{ m: 0 }}
              onClick={handleStartNavigation}
            >
              <NavigationIcon />
            </IconButton>
          </div>
        )}
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        {/* <GeneralBackdrop /> */}
        {loading ? <GeneralBackdrop /> : null}
        <style>
          {`
            /* Hide the Google Map terms, data, and keyboard shortcuts button */
            .gm-style > .gmnoprint, 
            .gm-style .gm-style-cc {
              display: none;
            }
          `}
        </style>
      </div>
    );
  };
  
  export default GoogleMap;