import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import bglight from '../../images/bglight.png'
import bgdark from '../../images/bgdark.png'
import bgmp from '../../images/bgmp.png'

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
  userSelect: 'none', // Prevent text selection
  WebkitUserSelect: 'none', // Specifically for Safari
  WebkitTapHighlightColor: 'transparent', // Remove tap highlight color on iOS
  outline: 'none'
};

export default function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const [bg, setBg] = React.useState('')

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  React.useEffect(() => { mode === 'dark' ? setBg(bgdark) : setBg(bglight) }, [mode])

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 10;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const updateDir = (lang) => {
      const direction = lang === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = direction; // Set direction on the root HTML element
      document.body.dir = direction; // Optionally set on body as well, for consistency
    };

    // Initial direction update
    updateDir(i18n.language);

    // Listen for language changes
    i18n.on('languageChanged', updateDir);

    // Cleanup function
    return () => {
      i18n.off('languageChanged', updateDir);
    };
  }, [i18n]);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <NavLink to='/' style={{paddingTop:'5px'}}>
              <img
                src={bg}
                style={logoStyle}
                alt="Devozilla"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              />
              </NavLink>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} >
                <MenuItem
                  onClick={() => scrollToSection('home')}
                  sx={{ py: '6px', px: '12px' }}
                  component={NavLink} to='home'
                >
                  <Typography variant="body2" color="text.primary">
                    {t('home')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('products')}
                  sx={{ py: '6px', px: '12px' }}
                  component={NavLink} to='products'
                >
                  <Typography variant="body2" color="text.primary">
                    {t('products')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('services')}
                  sx={{ py: '6px', px: '12px' }}
                  component={NavLink} to='services'
                >
                  <Typography variant="body2" color="text.primary">
                    {t('services')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('pricing')}
                  sx={{ py: '6px', px: '12px' }}
                  component={NavLink} to='pricing'
                >
                  <Typography variant="body2" color="text.primary">
                    {t('pricing')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('about')}
                  sx={{ py: '6px', px: '12px' }}
                  component={NavLink} to='about'
                >
                  <Typography variant="body2" color="text.primary">
                    {t('about')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('contact')}
                  sx={{ py: '6px', px: '12px' }}
                  component={NavLink} to='contact'
                >
                  <Typography variant="body2" color="text.primary">
                    {t('contact')}
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >

              <Button onClick={() => i18n.language === 'ar' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar')}>{i18n.language === 'ar' ? "English" : "العربية"}</Button>

              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />

              {/* <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-in/"
              >
                {t('login')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-up/"
              >
                {t('register')}
              </Button> */}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor={i18n.language === 'ar' ? 'right' : 'left'} open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <Box sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around'
                    }}>
                      <Button onClick={() => i18n.language === 'ar' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar')}>{i18n.language === 'ar' ? "English" : "العربية"}</Button>

                      <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                    </Box>
                  </Box>
                  <MenuItem onClick={() => scrollToSection('home')} component={NavLink} to='home'>
                    {t('home')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('products')} component={NavLink} to='products'>
                    {t('products')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('services')} component={NavLink} to='services'>
                    {t('services')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('pricing')} component={NavLink} to='pricing'>
                    {t('pricing')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('about')} component={NavLink} to='about'>
                    {t('about')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('contact')} component={NavLink} to='contact'>
                    {t('contact')}
                  </MenuItem>
                  {/* <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-up/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      {t('register')}
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      {t('login')}
                    </Button>
                  </MenuItem> */}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};
